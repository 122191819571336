import { useProductsStore } from '../stores/productsStore';
import { computed, onMounted, watch } from 'vue';
import { formatPrice } from '~/utils/formatters';
import type {
	ProductVariantOptions,
	Review,
	Product,
	PricingTierWithRules,
} from '~/types/app.types';

export default function useProductOptions(product: Product) {
	const ProductsStore = useProductsStore();
	const { pricingTiers }: { pricingTiers: PricingTierWithRules[] } = ProductsStore;

	const variant = ref<ProductVariantOptions>();
	const variantIndex = ref(0);
	const quantity = ref(1);

	const categories = computed(() => {
		if (product.productMetadata?.productCategories) {
			return product.productMetadata.productCategories.map((category) => category.name);
		}
		return [];
	});

	const isConcentrateProduct = computed(() => {
		return product.productMetadata?.productCategories?.some((category) =>
			['Concentrate', 'Vapes'].includes(category.name),
		)
			? true
			: false;
	});

	const chapter = computed(() => {
		if (product.productMetadata?.productCategories) {
			return product.productMetadata.productCategories.filter((el) =>
				/^Chapter \d/.test(el.name),
			)[0]?.name;
		}
		return null;
	});

	const tagIds = computed(() => {
		if (!product.tags?.length) return [];
		return product.tags.map((tag) => tag.id);
	});

	const averageRating = computed(() => {
		const ratings: number[] = [];
		product.productMetadata?.reviews
			?.filter((review: Review) => review.approved)
			.forEach((review) => ratings.push(review.rating));

		if (ratings.length) {
			const sum = ratings.reduce((acc, el) => acc + el, 0);
			return +(sum / ratings.length).toFixed(1);
		}

		return null;
	});

	const numberOfRatings = computed(() => {
		const count = product.productMetadata?.reviews
			? product.productMetadata.reviews.filter((review) => review.approved).length
			: 0;
		return `(${count})`;
	});

	const isPricingTierProduct = computed(() => !!product.pricingTierId);
	const isSingleProduct = computed(() => !product.pricingTierId && !product.groupedProductData);
	const isGroupedProduct = computed(() => !!product.groupedProductData);

	/**
	 * Convert grams into ounces for certain product sizes.
	 * @param startWeightGrams - The weight in grams to be converted.
	 * @returns - The converted weight in ounces or grams.
	 */
	function convertPricingTierGramsOption(startWeightGrams: number | null): string {
		if (startWeightGrams && startWeightGrams < 1) return `${startWeightGrams * 1000} mg`;
		const gramsOptionMap = {
			1: '1g',
			1.5: '1.5g',
			3.5: '1/8oz',
			5: '5g',
			7: '1/4oz',
			10: '10g',
			14: '1/2oz',
			20: '20g',
			28: '1oz',
			null: 'N/A',
		};

		if (!startWeightGrams) return 'N/A';

		return gramsOptionMap[startWeightGrams] || `${startWeightGrams}g`;
	}

	/**
	 * Generates an array of option objects for products using price tiers
	 *
	 * The function assumes that the 'startWeightGrams' of the first rule in each tier is the smallest size
	 * for that tier (1 unit). It uses this assumption to calculate a base unit multiplier for each subsequent
	 * pricing tier rule. This is used to align the display of product sizes on the website with the Dutchie POS
	 * system, which calculates quantities based on multiples of a product's base unit.
	 *
	 * @returns An array of option objects representing the pricing tier options. Each object
	 * contains the following properties:
	 *   - id: The Dutchie ID of the product.
	 *   - price: The price of the product formatted based on 'startWeightGrams' and 'pricePerGram'.
	 *   - size: The string representation of the 'startWeightGrams' converted via the 'convertPricingTierGramsOption' function.
	 *   - value: The 'startWeightGrams' of the pricing rule.
	 *   - productQuantityMultiplier: The multiplier value computed by dividing 'startWeightGrams' by 'baseUnit' (the smallest unit).
	 * If the product is not a Pricing Tier product, it returns undefined.
	 */
	const pricingTierOptions = computed(() => {
		if (isPricingTierProduct.value) {
			const tier = pricingTiers.filter((tier) => tier.id === product.pricingTierId)[0];

			const baseUnit = tier.pricingTierRules[0].startWeightGrams;

			// I need to include non-discounted prices within the tier options so that I can calcualte
			// the cart subtotal correctly. This is what I am using this value for
			const basePricePerGram = tier.pricingTierRules[0].pricePerGram;

			return tier.pricingTierRules.map((rule) => {
				return {
					id: product.dutchieId,
					price: formatPrice(rule.startWeightGrams, rule.pricePerGram),
					priceNonDiscounted: formatPrice(rule.startWeightGrams, basePricePerGram),
					size: convertPricingTierGramsOption(rule.startWeightGrams),
					grams: rule.startWeightGrams,
					productQuantityMultiplier: rule.startWeightGrams / baseUnit,
				};
			});
		}

		return null;
	});

	/**
	 * Create an array of grouped product data to feed into select component.
	 * @returns - An array of objects representing grouped product options.
	 */
	const groupedProductOptions = computed(() => {
		if (isGroupedProduct.value) {
			return product.groupedProductData
				?.filter(
					(variant) =>
						variant.inventory &&
						variant.inventory.some(
							(inventory) =>
								inventory.dutchieLocationId === ProductsStore.storeLocationId,
						),
				)
				.map((variant) => ({
					id: variant.id,
					price: variant.price,
					priceNonDiscounted: null,
					productQuantityMultiplier: null,
					size: variant.size,
					grams: variant.productGrams ?? 0,
				}));
		}

		return null;
	});

	/**
	 * Create a single price option object for non-pricing tier products.
	 * @returns - An object representing a single price option.
	 */
	function createSinglePriceOption() {
		return {
			id: product.dutchieId,
			price: product.price,
			priceNonDiscounted: null,
			productQuantityMultiplier: null,
			size: convertPricingTierGramsOption(product.productGrams),
			grams: product.productGrams,
		};
	}

	/** Set the initial value of the variant ref. */
	function setInitialVariantValue() {
		if (
			isPricingTierProduct.value &&
			pricingTierOptions.value &&
			pricingTierOptions.value.length > 0
		) {
			variant.value = pricingTierOptions.value[0];
		} else if (isSingleProduct.value) {
			variant.value = createSinglePriceOption();
		} else if (isGroupedProduct.value && groupedProductOptions.value) {
			variant.value = groupedProductOptions.value[0];
		}
	}

	/** Update variant ref when selection changes */
	watch(variantIndex, (newVariantIndex) => {
		if ((isPricingTierProduct.value, pricingTierOptions.value)) {
			variant.value = pricingTierOptions.value[newVariantIndex];
		} else if ((isGroupedProduct.value, groupedProductOptions.value)) {
			variant.value = groupedProductOptions.value[newVariantIndex];
		}
	});

	onMounted(setInitialVariantValue);

	return {
		averageRating,
		categories,
		chapter,
		createSinglePriceOption,
		groupedProductOptions,
		isConcentrateProduct,
		isGroupedProduct,
		isPricingTierProduct,
		isSingleProduct,
		numberOfRatings,
		pricingTierOptions,
		quantity,
		tagIds,
		variant,
		variantIndex,
	};
}
